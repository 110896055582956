<!--<style lang="scss" scoped>  @import "../styles/labels.scss"; </style>-->
<template>
  <div id="labels" class="pb-10 mb-10">
    <v-dialog
      v-model="showDatePicker"
      ref="dialog"
      width="290px"
      persistent
    >
      <v-date-picker
        v-model="datePickerDate"
        :range="isAdmin"
        no-title
        @close="!isAdmin && closeDatePicker()"
        @change="closeDatePicker"
      ></v-date-picker>
      <v-btn v-if="isAdmin" @click="closeDatePicker">Close</v-btn>
    </v-dialog>
    <v-toolbar flat class="d-print-none">
      <ProductionSelector
        :dates="dates"
        :production="production"
        v-on:update:dates="dates=$event"
        v-on:update:production="production=$event"
      />
      <template v-if="!basicView">
        <v-spacer/>
        <v-text-field
          v-model="searchEmail"
          label="Search by emails (separate with comma, can be partial)"
          clearable
        />
        <v-spacer/>
        <v-text-field
          v-model="searchName"
          label="Search by name  (separate with comma, can be partial)"
          clearable
        />
      </template>
    </v-toolbar>
    <v-progress-linear v-if="loading" :indeterminate="true"></v-progress-linear>
    <v-container class="dontPrint" v-if="!loading && !basicView">
      <v-row wrap row>
        <v-col class="text-xs-center py-2">
          <v-chip v-if="!loading">{{ orders.length }} of {{ allOrders.length }} labels</v-chip>
          {{ showAll ? `excluding ${extraOrders.length} extras/snacks` : '' }}
        </v-col>
        <v-col>
          <v-select
            label="Paginate"
            v-model="pageSize"
            :items="[null,250,500,1000]"
            clearable
          />
          <v-btn-toggle v-model="selectPage">
            <v-btn v-for="page of pages" v-bind:key="page">{{ page }}</v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col>
          <v-btn-toggle v-model="show">
            <v-btn value="all">All</v-btn>
            <v-btn value="filter">Apply Filter</v-btn>
            <v-btn value="meals">By Meal</v-btn>
          </v-btn-toggle>
          <v-select
            label="Exclude Tags"
            v-model="excludeTags"
            multiple
            :items="customerTags"
            clearable
          />
        </v-col>
      </v-row>
      <v-row wrap row v-if="showFilter">
        <v-col class="text-xs-center py-2">
          <v-btn-toggle v-model="streamSelection" multiple>
            <v-btn v-for="(stream,index) in activeStreams" v-bind:key="index" text
                   :value="stream.id">
              {{ stream.name }}
            </v-btn>
            <v-btn text :value="'chefs_choice'">
              chefs choice
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row wrap row v-if="showFilter" align="center">
        <v-col class="text-xs-center py-2">
          <v-btn-toggle v-model="sizeSelection" multiple>
            <v-btn
              v-for="size in ['small', 'medium', 'large']"
              v-bind:key="size" text :value="size">
              {{ size }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row wrap row v-if="showFilter" align="center">
        <v-col class="text-xs-center py-2">
          <v-btn-toggle v-model="todSelection" multiple>
            <v-btn text :value="tod" v-for="tod in tods" v-bind:key="tod">
              {{ tod }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-row xs12 class="text-xs-center mx-5 py-2">
          <v-col>
            <v-checkbox
              label="Show Only Orders With Restrictions"
              v-model="showOrdersWithRestrictions"
            />
          </v-col>
        </v-row>
      </v-row>
      <v-row align-center wrap row v-if="mealsNotFound" class="dontPrint">
        <v-spacer/>
        <v-col xs4 class="text-xs-center mx-5 py-2">
          <div v-for="(count,mealId) in mealsNotFound" v-bind:key="mealId">
            <v-alert type="warning"
                     :value="!!mealsNotFound[mealId]"
                     light
            >
              meal {{ mealId }} was not found (quantity ordered {{ count }})
            </v-alert>
          </div>
        </v-col>
        <v-spacer/>
      </v-row>
    </v-container>
    <v-container flat class="pa-4 dontPrint" v-if="hasMissingBinAssignment">
      <v-alert outlined text type="warning">
        <template v-if="missingBinAssignments.length>10">
          {{ missingBinAssignments.length }} orders are missing a bin assignment
        </template>
        <template v-else>
          {{ missingBinAssignments.length }} orders are missing a bin assignment:
          <ul>
            <li v-for="(name,i) of missingBinAssignments.map(o => [o.first_name, o.last_name].join(' ')).sort()"
                v-bind:key="i">{{ name }}
            </li>
          </ul>
        </template>
        <v-btn text outlined class="ml-6" :to="{ name: 'Packing', query: { dates: date}}">Go to Packing Page</v-btn>

      </v-alert>
    </v-container>
    <template v-if="showMeals">
      <v-container fluid>
        <v-alert type="warning" v-if="hasMissingBinAssignment">
          Printing is disabled until bins have been assigned.
        </v-alert>
        <v-toolbar flat class="mb-8">
          <v-btn-toggle v-model="mealFilter">
            <v-btn value="single">Single Serve Meals</v-btn>
            <v-btn value="other-sizes">Other Sizes</v-btn>
          </v-btn-toggle>
          <v-spacer/>
          <v-btn :color="selectMany?'success':'primary'"
                 :outlined="!selectMany"
                 :disabled="hasMissingBinAssignment"
                 @click="selectMany=!selectMany">
            Select Many
          </v-btn>
          <v-spacer/>
          <v-btn @click="clearDownloadTracker">Reset Downloads</v-btn>
        </v-toolbar>

        <v-app-bar
          v-if="selectMany"
          fixed
          bottom
          color="success"
        >
          <v-spacer/>
          <v-btn :loading="downloadingMany" :disabled="selectedCount===0" @click="multiselectDownload">
            Download Selected {{ selectedCount }}
          </v-btn>
          <v-spacer/>
          <v-btn @click="selectMany=false">
            Cancel
          </v-btn>
        </v-app-bar>

        <v-row dense wrap row v-for="(meal,i) of meals" v-bind:key="i">
          <v-col>
            <v-chip outlined :color="getDietColor(meal.diet)">{{ getDiet(meal.diet).name }}</v-chip>
            {{ meal.name }}
            <!--            {{ meal.tod }}-->
          </v-col>
          <v-col v-if="selectMany">
            <v-toolbar flat dense>
              <v-checkbox
                hide-details
                class="pa-1 mr-5 label-btn elevation-1"
                color="primary"
                :disabled="hasMissingBinAssignment || isAlreadyClicked[getKey(meal.id,{mealSize:'small'})]"
                v-if="getCountBySize(meal.id, 'small')>0"
                :loading="downloading[`${meal.id}-small`]"
                :label="`S ${getCountBySize(meal.id, 'small')}`"
                v-model="multiselect[`${meal.id}-small`]"
              >
              </v-checkbox>
              <v-checkbox
                hide-details
                class="pa-1 mr-5 label-btn elevation-1"
                color="primary"
                :disabled="hasMissingBinAssignment || isAlreadyClicked[getKey(meal.id,{mealSize:'medium'})]"
                v-if="getCountBySize(meal.id, 'medium')>0"
                :loading="downloading[`${meal.id}-medium`]"
                :label="`M ${ getCountBySize(meal.id, 'medium') }`"
                v-model="multiselect[`${meal.id}-medium`]"
              />
              <v-checkbox
                hide-details
                class="pa-1 mr-5 label-btn elevation-1"
                color="primary"
                :disabled="hasMissingBinAssignment || isAlreadyClicked[getKey(meal.id,{mealSize:'large'})]"
                v-if="getCountBySize(meal.id, 'large')>0"
                :loading="downloading[`${meal.id}-large`]"
                :label="`L ${ getCountBySize(meal.id, 'large') }`"
                v-model="multiselect[`${meal.id}-large`]"
              />
              <v-checkbox
                hide-details
                class="pa-1 mr-5 label-btn elevation-1"
                color="primary"
                v-if="getCountByRestrictions(meal.id)>0"
                :disabled="hasMissingBinAssignment || isAlreadyClicked[getKey(meal.id,{restricted:true})]"
                :loading="downloading[`${meal.id}-restricted`]"
                :label="`R ${ getCountByRestrictions(meal.id) }`"
                v-model="multiselect[`${meal.id}-restricted`]"
              />
            </v-toolbar>
          </v-col>
          <v-col v-if="!selectMany">
            <v-btn class="mr-5 label-btn"
                   color="primary"
                   :disabled="hasMissingBinAssignment || isAlreadyClicked[getKey(meal.id,{mealSize:'small'})]"
                   v-if="getCountBySize(meal.id, 'small')>0"
                   :loading="downloading[`${meal.id}-small`]"
                   @click="selectDownloadDisable(meal.id,{mealSize:'small'},$event)">
              S
              {{ getCountBySize(meal.id, 'small') }}
            </v-btn>
            <v-btn class="mr-5 label-btn"
                   color="primary"
                   :disabled="hasMissingBinAssignment || isAlreadyClicked[getKey(meal.id,{mealSize:'medium'})]"
                   v-if="getCountBySize(meal.id, 'medium')>0"
                   :loading="downloading[`${meal.id}-medium`]"
                   @click="selectDownloadDisable(meal.id,{mealSize:'medium'},$event)">
              M
              {{ getCountBySize(meal.id, 'medium') }}
            </v-btn>
            <v-btn class="mr-5 label-btn"
                   color="primary"
                   :disabled="hasMissingBinAssignment || isAlreadyClicked[getKey(meal.id,{mealSize:'large'})]"
                   v-if="getCountBySize(meal.id, 'large')>0"
                   :loading="downloading[`${meal.id}-large`]"
                   @click="selectDownloadDisable(meal.id,{mealSize:'large'},$event)">
              L
              {{ getCountBySize(meal.id, 'large') }}
            </v-btn>
            <v-btn class="label-btn"
                   color="primary"
                   v-if="getCountByRestrictions(meal.id)>0"
                   :disabled="hasMissingBinAssignment || isAlreadyClicked[getKey(meal.id,{restricted:true})]"
                   :loading="downloading[`${meal.id}-restricted`]"
                   @click="selectDownloadDisable(meal.id,{restricted:true},$event)">
              R
              {{ getCountByRestrictions(meal.id) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-if="!loading && !showMeals">
      <v-toolbar class="d-print-none" flat>
        <v-spacer/>
        <v-btn-toggle v-model="labelFormat">
          <v-btn value="large">Sticker</v-btn>
          <v-btn value="direct">Sleeve</v-btn>
        </v-btn-toggle>
        <v-spacer/>
      </v-toolbar>

      <template v-if="labelFormat==='large'">
        <div style="font-family: 'Gotham Light';" v-for="(orderItem, index) in ordersWithMeals" v-bind:key="index">
          <!--                <pre>{{ orderItem }}</pre>-->
          <MealLabelLarge
            :productionPrefix="productionPrefix"
            :page-number="getPageNumber(orderItem,index)"
            :meal-size="orderItem.meal_size"
            :get-best-before="getBestBefore(orderItem.order_date || orderItem.date)"
            :get-full-day="getFullDay(orderItem.order_date || orderItem.date)"
            :get-stream="streams[(orderItem.order_stream || orderItem.stream)] || { name: (orderItem.order_stream || orderItem.stream) }"
            :allergies="formatAllergies(orderItem)"
            :is-chefs-choice="orderItem.stream==='chefs_choice'"
            :order-item="orderItem"
            :qr-code-content="generateQrCode(orderItem)"
          />
        </div>
      </template>
      <v-toolbar v-if="labelFormat==='direct'" flat>
        <v-spacer/>
        <v-btn :loading="generatingPDF" @click="downloadPDF($event.target)">Download PDF</v-btn>
        <v-spacer/>
      </v-toolbar>
    </template>
  </div>
</template>
<script>

// import {mapGetters} from 'vuex';
// import moment from 'moment';
// import MealLabelSmall from '@/views/MealLabelSmall';
import MealLabelLarge from '@/views/MealLabelLarge';
import {mapActions, mapGetters} from 'vuex';
import {capitalise, getBestBefore, getDateString, getFullDay, getProductionDays, shortenBin} from '@/store/utils';
import urlState from "@/router/urlState";
import api from "@/api";
import moment from "moment/moment";
import ProductionSelector from "@/components/ProductionSelector.vue";
import diff from "microdiff";


export default {
  components: {
    ProductionSelector,
    MealLabelLarge
  },
  mixins: [urlState],

  async mounted() {
    if (this.basicView) {
      this.show = 'meals';
      this.excludeTags = ['DoNotPrint'];
    }
    this.syncToUrl({
      param: 'show',
      urlParam: 'view',
      initFromRoute: true,
    });
    this.syncToUrl({
      param: 'searchEmail',
      urlParam: 'email',
      initFromRoute: true,
    });
    this.syncToUrl({
      param: 'searchName',
      urlParam: 'name',
      initFromRoute: true,
    });
    this.syncToUrl({
      param: 'labelFormat',
      urlParam: 'format',
      initFromRoute: true,
    });
    this.syncToUrl({
      param: 'excludeTags',
      urlParam: 'exclude',
      initFromRoute: true,
      parseCallback: (v) => (v || '').split(','),
      transformCallback: (v) => v && v.join(',')
    });
    this.syncToUrl({
      param: 'streamSelection',
      urlParam: 'streams',
      initFromRoute: true,
      parseCallback: (v) => (v || '').split(','),
      transformCallback: (v) => v && v.join(',')
    });
    this.syncToUrl({
      param: 'sizeSelection',
      urlParam: 'sizes',
      initFromRoute: true,
      parseCallback: (v) => (v || '').split(','),
      transformCallback: (v) => v && v.join(',')
    });
    this.syncToUrl({
      param: 'todSelection',
      urlParam: 'tod',
      initFromRoute: true,
      parseCallback: (v) => (v || '').split(','),
      transformCallback: (v) => v && v.join(',')
    });

    this.syncToUrl({
      param: 'pageSize',
      urlParam: 'page_size',
      initFromRoute: true,
      parseCallback: (v) => Number.parseInt(v)
    });

    this.syncToUrl({
      param: 'selectPage',
      urlParam: 'page',
      initFromRoute: true,
      parseCallback: (v) => v && Number.parseInt(v)
    });

    this.syncToUrl({
      param: 'selectedMealId',
      urlParam: 'meal',
      initFromRoute: true,
      parseCallback: (v) => v && Number.parseInt(v)
    });

    this.syncToUrl({
      param: 'showOrdersWithRestrictions',
      urlParam: 'restrictions',
      initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'showAll',
      urlParam: 'all',
      initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });

    this.syncToUrl({
      param: 'dates',
      urlParam: 'dates',
      initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'production',
      urlParam: 'production',
      initFromRoute: true,
    });
    await this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData',
    showDatePicker(v) {
      if (v) {
        if (this.isAdmin) {
          this.datePickerDate = this.dates;
        } else {
          this.datePickerDate = this.dates[0];
        }
      } else {
        if (this.isAdmin) {
          this.dates = this.datePickerDate;
        } else {
          this.dates = getProductionDays(this.datePickerDate);
        }
      }
      this.dates.sort();
    },
  },
  data() {
    return {
      loading: true,
      sizeSelection: [],
      streamSelection: [],
      todSelection: [],
      substitutionSelection: [],
      showOrdersWithRestrictions: false,
      labelFormat: null,
      excludeTags: [],
      pageSize: null,
      selectPage: null,
      generatingPDF: null,
      show: null,
      selectedMealId: null,
      mealFilter: 'single',
      showDatePicker: null,
      dates: [],
      datePickerDate: null,
      searchEmail: null,
      searchName: null,
      downloading: {},
      isAlreadyClicked: {},
      selectMany: null,
      multiselect: {},
      downloadingMany: null,
      production: null,
      currentData: {dates: [], production: ''},
      ordersFetched: []
    }
  },
  computed: {
    ...mapGetters([
      'getDietName',
      'meals',
      'diets',
      'getMealsByDiet',
      'getDiet',
      'getDietStream',
      'getDietColor',
      'getStreams',
      'getTimeOfDay',
      'getSummaryForDiet',
      'getMeal',
      'getSubstitutionText',
      'getStreams'
    ]),
    date() {
      return this.dateFrom;
      //return this.$route.params.date;
    },
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    bestBeforeDate() {
      return getBestBefore(this.date);
    },
    fullDay() {
      return getFullDay(this.date);
    },
    meals() {
      const mealsById = {};
      console.log('this.allOrders', this.allOrders);
      this.allOrders
        // .filter(o => o.meal.missing)
        .forEach(o => {
          if (mealsById[o.meal_id] === undefined) {
            const meal = this.getMeal(o.meal_id);
            let isSingle = true;
            // const {name, tod} = meal;
            if (meal.name.match(/\d+ person/i) ||
              meal.tod == 4) {
              isSingle = false;
            }
            if (this.mealFilter === 'single') {
              mealsById[o.meal_id] = isSingle ? meal : false;
            } else if (this.mealFilter === 'other-sizes') {
              mealsById[o.meal_id] = !isSingle ? meal : false;
            } else {
              mealsById[o.meal_id] = meal;
            }
          }
        });
      console.log('meals', mealsById);
      return Object.values(mealsById).filter(m => !!m);
    },
    allOrders() {
      let orders = this.ordersFetched;

      // duplicate orders so that there is one order object per quantity ordered
      orders = [
        ...orders
          .filter(order => order.quantity === 1),
        ...orders
          .filter(order => order.quantity > 1)
          .flatMap(order =>
            new Array(order.quantity)
              .fill({
                ...order,
                quantity: 1
              }))  // set qty to 1 to avoid count issues
      ];
      const ordersWithoutMissing = orders.filter(o => !!o.meal_id);

      if (ordersWithoutMissing.length !== orders.length) {
        const ordersWitMissing = orders.filter(o => !o.meal_id);
        console.log(`warning: ${orders.length - ordersWithoutMissing.length} orders are missing (out of ${orders.length}) because meal id missing`, {
          ordersWitMissing,
          orders,
          ordersWithoutMissing
        });
        alert(`warning: ${orders.length - ordersWithoutMissing.length} orders are missing (out of ${orders.length}) because meal could not be loaded`);
      }
      return ordersWithoutMissing;
    },
    extraOrders() {
      return this.allOrders.filter(o => (o.tod === 'extras' || o.tod === 'snacks'));
    },
    ordersFiltered() {
      let orders;
      orders = this.allOrders.slice();
      // console.log('all orders', {date: this.date, orders});
      // console.log('streams', this.streams)

      const excludeTags = this.excludeTags;
      if (excludeTags && excludeTags.length > 0) {
        // console.log('excluding', excludeTags)
        orders = orders.filter(o => !(o.tags && excludeTags.every(t => o.tags.includes(t))));
      }

      if (this.searchEmail) {
        const emails = this.searchEmail
          .split(',')
          .map(s => s.trim().toLowerCase())
          .filter(s => !!s)

        // eslint-disable-next-line no-inner-declarations
        function isMatch(email) {
          for (const matchEmail of emails) {
            if (email.toLowerCase().indexOf(matchEmail) > -1) {
              return true;
            }
          }
          return false;
        }

        orders = orders.filter(o => isMatch(o.email));
      }
      if (this.searchName) {
        const names = this.searchName
          .split(',')
          .map(s => s.trim().toLowerCase())
          .filter(s => !!s)

        // eslint-disable-next-line no-inner-declarations
        function isMatch(n) {
          for (const m of names) {
            if (n.toLowerCase().indexOf(m) > -1) {
              return true;
            }
          }
          return false;
        }

        orders = orders.filter(o => isMatch(o.email));
      }
      if (this.selectMany) {
        const keys = Object.keys(this.multiselect)
          .filter(k => this.multiselect[k]);
        const selectedOrders = [];
        for (const key of keys) {
          let [mealId, type] = key.split('-');
          mealId = Number.parseInt(mealId)
          if (type === 'restricted') {
            selectedOrders.push(...orders
              .filter(o => o.meal_id === mealId && o.allergyDetected)
            )
          } else {
            const meal_size = type;
            selectedOrders.push(...orders
              .filter(o => o.meal_id === mealId && o.meal_size === meal_size && !o.allergyDetected)
            )
          }
        }
        orders = selectedOrders;
      } else if (this.showAll) {
        orders = orders.filter(o => (o.tod !== 'extras' && o.tod !== 'snack'));
      } else if (this.showMeals) {
        if (this.selectedMealId) {
          orders = orders.filter(o => o.meal_id === this.selectedMealId)
        }
        if (this.showOrdersWithRestrictions) {
          orders = orders.filter(o => o.allergyDetected);
        } else {
          orders = orders.filter(o => !o.allergyDetected);
        }
        console.log('filters', [this.sizeSelection, this.streamSelection, this.todSelection]);
        orders = orders.filter(o =>
          (this.sizeSelection.length === 0 || this.sizeSelection.includes(o.meal_size))
        );

      } else {
        if (this.showOrdersWithRestrictions) {
          orders = orders.filter(o => (o.allergies && o.allergies.length > 0));
        } else {
          orders = orders.filter(o => !(o.allergies && o.allergies.length > 0));
        }
        console.log('filters', [this.sizeSelection, this.streamSelection, this.todSelection]);
        orders = orders.filter(o =>
          (this.sizeSelection.includes(o.meal_size)) &&
          (this.todSelection.includes(o.tod)) &&
          (this.streamSelection.includes(o.stream))
        );
      }

      // inject meal
      orders.forEach(o => {
        o.meal = this.getMeal(o.meal_id);
        if (!o.meal) {
          o.meal = {
            name: 'could not find meal id ' + o.meal_id,
            missing: true
          };
        }
      });

      orders.forEach(o => {
          const restrictions = o.allergies || [];
          // sort value: no allergies (-1) < no matched allergies (0) < matched allergies (1)
          if (restrictions.length === 0) {
            o.restrictionSort = -1;
          } else {
            o.restrictionSort = o.restrictedFoods ? 1 : 0;
          }
          o.restrictionTagsText = new Map(
            restrictions
              .map(a => [a, this.getSubstitutionText(a)])
              .sort((a, b) => a[1].localeCompare(b[1]))
          )
        }
      );
      orders.forEach(o => o.restrictionTagsString = Object.values(o.restrictionTagsText).join(','));
      orders.forEach(o => o.streamName = (this.streams[o.stream] || {name: o.order_type}).name);
      orders.forEach(o => o.isMultiplePersonMeal = (o.meal && o.meal.name && o.meal.name.match(/\d+ person/i) && o.meal.name));

      orders = orders.sort((a, b) => {
        if (a.isMultiplePersonMeal && !b.isMultiplePersonMeal) {
          return -1;
        }
        if (!a.isMultiplePersonMeal && b.isMultiplePersonMeal) {
          return 1;
        }
        if (a.isMultiplePersonMeal && b.isMultiplePersonMeal) {
          return a.isMultiplePersonMeal.localeCompare(b.isMultiplePersonMeal);
        }
        const nameA = a.streamName;
        const nameB = b.streamName;
        if (nameA !== nameB) {
          return nameA.localeCompare(nameB);
        }
        if (a.meal.name !== b.meal.name) {
          return a.meal.name.localeCompare(b.meal.name);
        }
        if (a.meal.tod !== b.meal.tod) {
          return a.meal.tod - b.meal.tod;
        }
        if (a.restrictionSort !== b.restrictionSort) {
          return a.restrictionSort - b.restrictionSort
        }
        if (a.restrictionTagsString !== b.restrictionTagsString) {
          return a.restrictionTagsString.localeCompare(b.restrictionTagsString);
        }
        if (a.meal_size !== b.meal_size) {
          // note reverse compare, as (s)mall, (m)edium, (l
          return b.meal_size.localeCompare(a.meal_size);
        }
        return `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`);
      });
      console.log('filtered orders', {
        date: this.date,
        orders
      });
      return orders;
    },
    orders() {
      let orders = this.ordersFiltered;
      if (this.pageSize) {
        if (this.selectPage !== null) {
          const start = this.selectPage * this.pageSize;
          const end = (start + this.pageSize);
          console.log('showing from ', {
            start,
            end
          });
          orders = orders.slice(start, end);
        } else {
          orders = [];
        }
      }
      return orders;
    },
    ordersWithMeals() {
      if (this.loading) {
        return [];
      } else {
        return this.orders.filter(o => !o.meal.missing);
      }
    },
    mealsNotFound() {
      const mealIds = {};
      this.orders
        .filter(o => o.meal.missing)
        .forEach(o => mealIds[o.meal_id] = (mealIds[o.meal_id] || 0) + 1);
      console.log('mealsNotFound', mealIds);
      return mealIds;
    },
    streams() {
      const streams = this.getStreams();  //Object.values(this.diets).map(diet => JSON.stringify(diet.name));
      console.log('streams', streams);
      return streams;
    },
    missingBinAssignments() {
      return (this.ordersWithMeals || []).filter(o => !o.bin);
    },
    customerTags() {
      const tags = new Set();
      this.allOrders.forEach(o => {
        if (o.tags) {
          o.tags.forEach(t => tags.add(t));
        }
      })
      return [...tags];
    },
    tods() {
      return [...new Set(['breakfast', 'lunch', 'dinner', 'extras', ...this.allOrders.map(o => o.tod)])];
    },
    pageCount() {
      return (this.pageSize) ? Math.ceil(this.ordersFiltered.length / this.pageSize) : 1;
    },
    pages() {
      const result = [];
      if (this.pageSize) {
        let page = 0;
        let pages = this.pageCount;
        while (page++ < pages) {
          result.push(page)
        }
      }
      return result;
    },
    showAll() {
      return this.show === 'all';
    },
    showFilter() {
      return this.show === 'filter'
    },
    showMeals() {
      return !this.loading && this.show === 'meals';
    },
    getDatesInRange() {
      const dates = [];
      if (!this.dateFrom || !this.dateTo) {
        return dates;
      }
      const from = moment(this.dateFrom);
      const to = moment(this.dateTo);

      const diff = moment.duration(to.diff(from));
      if (diff.asDays() >= 0 && diff.asDays() <= 31) {
        // console.log('adding days ', {from, to})
        while (from.isSameOrBefore(to)) {
          const dateString = getDateString(from);
          // console.log('added date', dateString);
          dates.push(dateString);
          from.add(1, 'day');
        }
      } else {
        window.alert(diff.asDays() + ' days is above the limit of 7');
      }
      // console.log('getDatesInRange', dates);
      return dates;
    },
    selectedCount() {
      return Object.values(this.multiselect).filter(v => !!v).length
    },
    hasMissingBinAssignment() {
      return this.missingBinAssignments.length > 0;
    },
    alreadyClickedStorageKey() {
      return `PROD-${this.production}-${this.dates.join('-')}-labels`;
    },
    productionPrefix() {
      return `${this.production[0]}-`.toUpperCase();
    },
    activeStreams() {
      const activeStreams = new Set(this.ordersFetched.map(o => o.stream));
      return Object.values(this.streams).filter(s => activeStreams.has(s.id));
    }
  },
  methods: {
    ...mapActions([
      'fetchDiets',
      'fetchStreams',
      'fetchMeal',
      'fetchRestrictions'
    ]),
    getFullDay,
    getBestBefore,
    async fetchData() {
      const dates = this.dates;
      const production = this.production;
      this.isAlreadyClicked = {};
      this.multiselect = {};
      // const value = `M-${mealId}-${JSON.stringify(options)}`;

      const current = JSON.parse(localStorage.getItem(this.alreadyClickedStorageKey) || '[]');
      current.forEach(key => this.isAlreadyClicked[key] = true);

      if (!dates || dates.length === 0) {
        console.log('no dates, no fetch');
        this.loading = false;
        return;
      }
      if (!production) {
        console.log('no production, no fetch');
        this.loading = false;
        return;
      }

      if (diff(this.currentData, {dates, production}).length === 0) {
        // console.log('already loaded', this.currentData);
        return
      } else {
        console.log('fetching prep', {dates, production});
        this.currentData = {dates, production};
      }

      this.error = this.post = null

      console.log('fetching data ', {date: this.date});
      this.loading = true;
      const orders = await api
        .get('v2/order/search', {
          params: {
            from: this.dateFrom,
            to: this.dateTo,
            productions: [this.production],
            // properties: ['quantity', 'allergyDetected', 'allergies', 'meal_id', 'stream', 'tod', 'meal_size'],
          }
        })
        .then(({data}) => data);
      this.ordersFetched = orders;
      return Promise
        .all([
          ...orders.map(o => {
            if (o.meal_id) {
              return this.fetchMeal(o.meal_id).catch(e => {
                console.warn('failed to fetch meal ' + o.meal_id, e);
              });
            } else {
              console.warn('no meal id for order', o);
            }
          }),
          this.fetchDiets(),
          this.fetchStreams(),
          this.fetchRestrictions()
        ])
        .catch(e => {
          console.error('failed loading data', e);
          alert('error: ' + e);
          throw e;
        })
        .finally(() => this.loading = false);

    },
    formatAllergies(orderItem) {
      return orderItem.restrictionTagsText;
      // return (orderItem.allergies || []).map(a => this.getSubstitutionText(a));
    },
    generateQrCode(orderItem) {
      const size = orderItem.meal_size.substring(0, 1).toLowerCase();
      // const bin = orderItem.bin ? `?bin=${orderItem.bin}` : '';
      if (orderItem.squid) {
        return `https://fed.fyi/#/s/${orderItem.squid}`;
      } else {
        const mealId = orderItem.meal_id;
        return `https://fed.fyi/#/chart/fedfedfed/${mealId}/${size}`;
      }
    },
    labelFilename() {
      let isWindows = true;
      try {
        if (navigator.platform.indexOf('Win') === -1) {
          isWindows = false;
        }
      } catch (e) {
        console.error('could not detect os')
      }

      const {
        excludeTags,
        streamSelection,
        sizeSelection,
        todSelection,
        pageSize,
        selectPage,
        pageCount,
        showOrdersWithRestrictions,
        showAll,
        showMeals,
        date
      } = this;

      const filenameParts = [];
      filenameParts.push(date);
      filenameParts.push(moment(date).format('ddd'));
      filenameParts.push(this.production);
      if (showAll) {
        filenameParts.push('all');
      } else {
        if (showMeals) {
          if (this.selectMany) {
            const keys = Object.keys(this.multiselect)
              .filter(k => this.multiselect[k])
              .map(s => `M${s}`);
            filenameParts.push(...keys);
          } else if (this.selectedMealId) {
            const meal = this.getMeal(this.selectedMealId);
            const dietName = this.getDietName(meal.diet);
            filenameParts.push(...dietName.split(' '));
            filenameParts.push(`M${meal.id}`);
          }
        }

        if (showOrdersWithRestrictions) {
          filenameParts.push('restrictions');
        }

        if (streamSelection.length > 0) {
          filenameParts.push(...streamSelection)
        }
        if (todSelection.length > 0) {
          filenameParts.push(...todSelection)
        }
        if (sizeSelection.length > 0) {
          filenameParts.push(...sizeSelection)
        }
        if (excludeTags.length > 0) {
          filenameParts.push(...excludeTags.map(t => `exclude-${t}`));
        }
        if (pageSize) {
          filenameParts.push(`page-${selectPage + 1}-of-${pageCount}`);
        }
      }
      const filename = `${filenameParts.join('-')}`;
      if (isWindows && filename.length > 245) {
        return filename.slice(0, 245) + '(clip).pdf';
      } else {
        return `${filename}.pdf`;
      }
    },
    async downloadPDF(element, clickKey) {
      console.log('download pdf');
      if (clickKey && this.isAlreadyClicked[clickKey]) {
        console.log('already downloaded', element);
        return;
      }
      const payload = [];
      let index = 0;
      this.generatingPDF = true;

      const orders = this.ordersWithMeals;
      if (orders.length === 0) {
        alert('No meals selected, nothing to print');
        return false;
      }

      let lastMealId;
      for (const orderItem of orders) {
        if (lastMealId !== orderItem.meal.id) {
          index = 0;
          lastMealId = orderItem.meal.id;
        }
        const ingredients = orderItem.meal.ingredients.map(ingredient => ({
          isRestricted: orderItem.restrictedFoods && orderItem.restrictedFoods[ingredient.food_id],
          name: ingredient.name
        }));
        const restrictions = [];
        if (orderItem.restrictionTagsText) {
          for (const key of orderItem.restrictionTagsText.keys()) {
            const val = orderItem.restrictionTagsText.get(key);
            restrictions.push({
              name: val,
              detected: !!(orderItem.allergyDetected && orderItem.allergyDetected[key])
            });
          }
        }
        let stream = (this.streams[orderItem.order_stream || orderItem.stream] || {name: orderItem.stream}).name;
        if (stream === 'chefs_choice') {
          stream = `Chef's Choice`;
        }
        const label = {
          day: getFullDay(orderItem.order_date || orderItem.date),
          bestBefore: getBestBefore(orderItem.date),
          size: capitalise(orderItem.meal_size),
          tod: capitalise(orderItem.order_tod || orderItem.tod),
          stream,
          customerName: `${orderItem.first_name} ${orderItem.last_name}`,
          mealName: orderItem.meal.name,
          ingredients,
          directions: orderItem.meal.instructions.join('<br/>'),
          labelId: this.getPageNumber(orderItem, index),
          mealId: `M${orderItem.meal.id}`,
          filingId: this.productionPrefix + shortenBin(orderItem.bin || ''),
          restrictions,
          qrcodeUrl: this.generateQrCode(orderItem),
          showTruck: this.production !== 'local'
        }

        payload.push(label);

        index++;
      }

      try {
        const response = await api.post('v2/label/pdf', payload, {responseType: 'blob'});
        const blob = new Blob([response.data], {type: response.headers['content-type']});
        const link = document.createElement('a');
        if (element) {
          // this prevents the page from jumping when the click event happens
          element.parentElement.appendChild(link);
        }

        link.href = URL.createObjectURL(blob);
        link.download = this.labelFilename();
        // maybe this will stop it downloading many times?
        link.click();
        URL.revokeObjectURL(link.href);
        this.generatingPDF = false;
        return true;
      } catch (e) {
        this.generatingPDF = false;
        console.error('failed', e);
        return false;
      }


    },
    getPageNumber(orderItem, index) {
      if (this.pageSize) {
        return [`${orderItem.meal.id}`.padStart(4, '0'), this.selectPage + 1, this.pageCount, `${index + 1001}`.padStart(4, '0')].join('-')
      } else {
        return [`${orderItem.meal.id}`.padStart(4, '0'), `${index + 1001}`.padStart(4, '0')].join('-')
      }
    },

    getCountBySize(mealId, mealSize) {
      let orders = this.allOrders;
      const excludeTags = this.excludeTags;
      if (excludeTags && excludeTags.length > 0) {
        // console.log('excluding', excludeTags)
        orders = orders.filter(o => !(o.tags && excludeTags.every(t => o.tags.includes(t))));
      }
      return orders
        .filter(o => o.meal_id === mealId)
        .filter(o => o.meal_size === mealSize)
        .filter(o => !o.allergyDetected)
        .reduce((sum, o) => sum + o.quantity, 0);
    },
    getCountByRestrictions(mealId) {
      let orders = this.allOrders;
      const excludeTags = this.excludeTags;
      if (excludeTags && excludeTags.length > 0) {
        // console.log('excluding', excludeTags)
        orders = orders.filter(o => !(o.tags && excludeTags.every(t => o.tags.includes(t))));
      }
      return orders
        .filter(o => o.meal_id === mealId)
        .filter(o => o.allergyDetected)
        .reduce((sum, o) => sum + o.quantity, 0);
    },
    async multiselectDownload() {
      this.$nextTick(async () => {
        const dayKey = this.alreadyClickedStorageKey;
        if (await this.downloadPDF()) {
          const keys = Object.keys(this.multiselect)
            .filter(k => this.multiselect[k]);
          for (const key of keys) {
            let [mealId, type] = key.split('-');
            mealId = Number.parseInt(mealId);
            const options = {};
            if (type === 'restricted') {
              options.restricted = true;
            } else {
              const meal_size = type;
              options.mealSize = meal_size;
            }
            this.isAlreadyClicked[this.getKey(mealId, options)] = true;
            this.multiselect[key] = false;
          }
          localStorage.setItem(dayKey, JSON.stringify(Object.keys(this.isAlreadyClicked)));
        } else {
          console.warn('this download returned false');
        }
        this.downloadingMany = false;
      })
      this.downloadingMany = true;
    },

    async selectDownloadDisable(mealId, options, $event) {
      console.log('selectDownloadDisable', {mealId, options, $event});
      const {
        mealSize,
        restricted
      } = options;
      this.selectedMealId = mealId;
      this.sizeSelection = [];
      this.showOrdersWithRestrictions = !!restricted;
      let key;
      if (mealSize) {
        key = `${mealId}-${mealSize}`;
        this.sizeSelection = [mealSize];
      } else if (restricted) {
        key = `${mealId}-restricted`;
      }
      if (this.downloading[key]) {
        console.log('this.downloading[key] - already downloading', this.downloading[key]);
        return;
      }
      if (key) {
        this.$set(this.downloading, key, true);
      }

      this.$nextTick(async () => {
        const clickKey = this.getKey(mealId, options);
        if (await this.downloadPDF($event.target, clickKey)) {
          const dayKey = this.alreadyClickedStorageKey;
          this.isAlreadyClicked[clickKey] = true;
          localStorage.setItem(dayKey, JSON.stringify(Object.keys(this.isAlreadyClicked)));

          if (key) {
            this.$set(this.downloading, key, false);
          }
        } else {
          console.warn('this download returned false');
        }
      })
    },

    clearDownloadTracker() {
      if (confirm('Are you sure?')) {
        const key = this.alreadyClickedStorageKey;
        localStorage.removeItem(key);
        window.location.reload();
      }
    },
    closeDatePicker() {
      this.showDatePicker = false;
      this.$nextTick(() => this.fetchData());
    },
    getKey(mealId, options) {
      return `M-${mealId}-${JSON.stringify(options)}`;
    },
  },
  props: {
    basicView: {
      type: Boolean,
      default: false,
      required: false
    },
    isAdmin: {
      type: Boolean,
      default: false,
      required: false
    },
  }
}

</script>


<style>
.label-btn {
  width: 80px;
  /*max-width: 60px;*/
  /*overflow: clip;*/
  text-overflow: ellipsis;
}
</style>